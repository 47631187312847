import Image from 'next/image'

const smallLogos = [
  { path: 'today', alt: 'Today' },
  { path: 'usa-today', alt: 'USA Today' },
  { path: 'business-insider', alt: 'Business Insider' },
  { path: 'romper', alt: 'Romper' },
  { path: 'healthline', alt: 'Healthline' },
  { path: 'crunchbase', alt: 'Crunchbase' },
]

const largeLogos = [
  { path: 'today', alt: 'Today', width: 60, height: 80 },
  { path: 'business-insider', alt: 'Business Insider', width: 101, height: 80 },
  { path: 'healthline', alt: 'Healthline', width: 121, height: 80 },
  { path: 'usa-today', alt: 'USA Today', width: 100, height: 80 },
  { path: 'crunchbase', alt: 'Crunchbase', width: 121, height: 80 },
  { path: 'red-tricycle', alt: 'Red Tricycle', width: 101, height: 80 },
  { path: 'romper', alt: 'Romper', width: 90, height: 80 },
]

export default function LogoSection() {
  return (
    <section className="pt-1 md:pt-8">
      <div className="container px-2.5 md:px-7.5">
        <div className="grid grid-cols-3 gap-5 pb-2.5 md:hidden">
          {smallLogos.map((logo) => (
            <div key={'smalllogo-' + logo.path} className="flex items-center justify-center">
              <Image
                src={'/images/about/company-logos/mobile/' + logo.path + '@2x.png'}
                height={55}
                width={100}
                alt={logo.alt}
              />
            </div>
          ))}
        </div>
        <div className="hidden flex-row justify-between pb-5 pt-1.5 md:flex">
          {largeLogos.map((logo) => (
            <div key={'largelogo-' + logo.path}>
              <Image
                src={'/images/about/company-logos/' + logo.path + '@2x.png'}
                height={logo.height}
                width={logo.width}
                alt={logo.alt}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
