import { PlayIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { BackgroundVideoPlayer } from '~/components/video/videoPlayer'

import { TitleLabelSecondary } from '../elements/labels/TitleLabels'
import ModalVideoPlayer from './ModalVideoPlayer'

type Props = {
  backgroundVideoUrl: string
  modalVideoUrl: string
  title: string
  subtitle?: string
}

export default function BackgroundVideoPlayerWithModal({
  backgroundVideoUrl,
  modalVideoUrl,
  title,
  subtitle,
}: Props) {
  let [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <section className="relative flex min-h-[500px] dark:bg-grey-alt md:border-0">
        <BackgroundVideoPlayer url={backgroundVideoUrl} />
        <div className="absolute flex h-full w-full bg-black/[.35] ">
          <div className="z-9 absolute h-full w-full  bg-gradient-to-b from-transparent to-black-alt to-90%"></div>
          <div className="container z-10 flex max-w-2xl flex-col justify-center text-center text-white">
            <TitleLabelSecondary largeFont={true}>{title}</TitleLabelSecondary>
            {subtitle && (
              <div className="pb-0 text-center text-xs font-semibold text-white md:pb-12 md:pt-2 md:text-base ">
                {subtitle}
              </div>
            )}

            <button
              onClick={() => {
                setIsOpen(true)
              }}
            >
              <div className="flex flex-row items-center justify-center">
                <PlayIcon className="mr-2 h-5 w-5 text-white md:h-10 md:w-10" />
                <div className="text-xl.3 font-bold text-white md:text-3xl">Watch Now</div>
              </div>{' '}
            </button>
          </div>
        </div>
      </section>

      <ModalVideoPlayer open={isOpen} setIsOpen={setIsOpen} url={modalVideoUrl} />
    </>
  )
}
