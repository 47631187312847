import { StarIcon } from '@heroicons/react/solid'

// Large-format card used to render a review "testimonial"

type TestimonialCardProps = {
  title?: string
  stars: number
  author?: string
  large_text?: boolean
  children: React.ReactNode
}

export default function TestimonialCard(props: TestimonialCardProps) {
  return (
    <div className="mb-3 flex select-none flex-col gap-8">
      {props.title && (
        <div className="text-xl.3 font-bold text-white  md:text-2xl">{props.title} </div>
      )}

      <div className="flex flex-row">
        {[...Array(props.stars)].map((n, i) => (
          <StarIcon key={i} className="h-6 w-6 text-sea-foam" />
        ))}
      </div>

      {props.large_text ? (
        <div className="text-2xl font-bold text-grey-text md:text-3xl md:font-normal">
          {props.children}
        </div>
      ) : (
        <div className="leading-tight text-white">{props.children}</div>
      )}

      {props.author && (
        <p className="text-md font-super-new uppercase text-grey-text md:text-xl">{props.author}</p>
      )}
    </div>
  )
}
